body {
  background-color: #3A3A3A;
  color: #EFEFAE;
  font-family: 'Fira Mono', Monospace;
  margin: 0;
  overflow-x: hidden;
}

input {
  font-family: 'Fira Mono', Monospace;  
  font-size: 14px;
}

body a {
  color: inherit;
}

body a:hover {
  color: #FFFFAE;
  background-color: #005F5F;
}

body .stream {
  margin-top: 8px;
}

body .line {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  margin: 0 4px;
  padding-top: 2px;
  width: calc(100% - 12px);
}

body .line p {
  display: inline-block;
  margin: 0;
  padding: 0;
  white-space: pre;
}

body .line .name {
  text-align: right;
  padding-right: 6px;
  color: #d75f5f;
}

body .information {
  max-width: 120px;
  min-width: 120px;
  padding-right: 6px;
  color: #EFEFAE;
  border: none;
}

body .editline {
  background-color: #262626;
  padding: 2px 4px 0px 4px;
  width: calc(100%);
  margin: 0;
  margin-bottom: -25px;
}

body .editline .edit {
  min-width: calc(100% - 200px);
  outline: none;
  background-color: #262626; 
  height: 16.6px;
  margin:auto;
  margin-left: -1px;
}

body .editline .time {
  user-select: none;
  cursor: default;
  padding-right: 2px;
  color: #EFEFAE;
}

footer {
  margin-top: 30px;
}

.whitet {
  color: #FFFFAE;
}

.redt {
  color: #d75f5f;
}

.important {
  color: #E3A786;
}

.bluet {
  color: #5f8787;
}

.greent {
  color: #afaf00;
}

.selft {
  color: #83A598;
}

::selection {
  color: #FFFFAE;
  background: #005F5F;
}

::-webkit-scrollbar {
  background-color: #3A3A3A;
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #bcbcbc;
}

::-webkit-scrollbar-corner {
  background-color: #3A3A3A;
}

::-webkit-resizer {
  background-color: #3A3A3A;
}

.phjspenheader:hover {
  background-color: #D44C2A;
}
